export const portfolioActions = {
    PORTFOLIO_SELECTED_CHANGE: 'portfolio/SelectedChange',
    RESET: 'PORTFOLIO_RESET',
    DELETE_PORTFOLIO_CONFIRMATION: 'PORTFOLIO_DELETE_PORTFOLIO_CONFIRMATION',
    SHOW_BWIC_DETAILS: 'PORTFOLIO_SHOW_BWIC_DETAILS',
    NEW_PORTFOLIO_DIALOG: 'PORTFOLIO_NEW_PORTFOLIO_DIALOG',
    SCHEDULED_BIDDING_SECURITIES_DIALOG: 'PORTFOLIO_SCHEDULED_BIDDING_SECURITIES_DIALOG',
    EXPAND_ALL: 'PORTFOLIO_EXPAND_ALL',
    EXPANDED_CHANGE: 'portfolio/ExpandedChange',

    PORTFOLIO_UPDATE_ALERT_STATUS_REQUEST: 'PORTFOLIO_UPDATE_ALERT_STATUS_REQUEST',
    PORTFOLIO_UPDATE_ALERT_STATUS_SUCCESS: 'PORTFOLIO_UPDATE_ALERT_STATUS_SUCCESS',
    PORTFOLIO_UPDATE_ALERT_STATUS_FAILURE: 'PORTFOLIO_UPDATE_ALERT_STATUS_FAILURE',

    UPDATE_INVENTORY_ALERT_STATUS_REQUEST: 'PORTFOLIO_UPDATE_INVENTORY_ALERT_STATUS_REQUEST',
    UPDATE_INVENTORY_ALERT_STATUS_SUCCESS: 'PORTFOLIO_UPDATE_INVENTORY_ALERT_STATUS_SUCCESS',
    UPDATE_INVENTORY_ALERT_STATUS_FAILURE: 'PORTFOLIO_UPDATE_INVENTORY_ALERT_STATUS_FAILURE',

    PORTFOLIO_SET_IS_REQUESTING_EXPORT: 'PORTFOLIO_SET_IS_REQUESTING_EXPORT',
    ORDER_BY_CHANGE: 'portfolio/OrderByChange',
    ADVANCED_FILTER_BLOCKED: 'portfolio/ADVANCED_FILTER_BLOCKED',
};
