import { StatisticsAlertOption } from "../../types/amr-pipeline/enums/AlertOption";
import { AlertOption } from "../../types/email-preferences/EmailPreferences";

export const alertOptions = [AlertOption.Never, AlertOption.Daily, AlertOption.Instant];

export const statisticsAlertOptions = [
    StatisticsAlertOption.Never,
    StatisticsAlertOption.Weekly,
];

export const analyticAlertOptions = [AlertOption.Never, AlertOption.Daily];
export const secondaryStatsAlertOptions = [AlertOption.Never, AlertOption.Instant];

export const alertOptionTooltipText = {
    [AlertOption.Daily]: 'Alerts are sent at 5 PM EST every business day excluding holidays if manager’s transactions are published or updated',
    [AlertOption.Instant]: 'Alerts are sent once manager’s transactions are published or updated',
    [StatisticsAlertOption.Weekly]: 'Alerts are sent at 3 PM EST every Friday',
};
